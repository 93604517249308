import Vue from 'vue'
import App from './App.vue'

import store from "./store/Store";
import router from "./router/Router"
import '../src/assets/styles/All.css'

import  "./interceptor.js";//<script src="../../assets/vendor/js/menu.js"></script>



import 'bootstrap';

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr);

import vSelect from 'vue-select'

Vue.component('v-select', vSelect);





import { BootstrapVue } from 'bootstrap-vue'

Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin

import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );


import '@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.min.css';
const VueClockPickerPlugin = require('@pencilpix/vue2-clock-picker/dist/vue2-clock-picker.plugin.js')
Vue.use(VueClockPickerPlugin)

import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

import 'vue-select/dist/vue-select.css';

/* import './assets/vendor/libs/shepherd/shepherd.js'
import './assets/js/extended-ui-tour.js' */




import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

import VueSkeletonLoader from 'skeleton-loader-vue';

// Register the component globally
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true
});

import LazyComponent from "v-lazy-component/vue2";

Vue.use(LazyComponent);

import VueHtmlToPaper from 'vue-html-to-paper';

import VueIntro from 'vue-introjs'
Vue.use(VueIntro)

import 'intro.js/introjs.css';

const options = {
  name: '_blank',
  /* specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ], */
  styles: [
    'https://demos.pixinvent.com/vuexy-html-admin-template/assets/vendor/css/rtl/core.css',
    'https://apitest.venuebook.in/Upload/css/print.css'
  ],
  timeout: 1000,
  autoClose: false,
  windowTitle: window.document.title,
}

import { TooltipPlugin } from 'bootstrap-vue'
Vue.use(TooltipPlugin)

Vue.use(VueHtmlToPaper, options);

Vue.use(VueHtmlToPaper);

/* import Echo from "laravel-echo"
window.Pusher = require('pusher-js');

window.Echo = new Echo({
   broadcaster: 'pusher',
   key: "Notification",
   wsHost:"apitest.venuebook.in",
   wsPort: '',
   cluster: "mt1",
   forceTLS: false,
   disableStats: true,
}); */

Vue.config.productionTip = false



new Vue({  store, router, render: (h) => h(App),scrollBehavior() {
  return new Promise((resolve) => {
    resolve({ left: 0, top: 0 })
  })
} }).$mount("#app");
